import React, { useState, useCallback, useEffect, useRef } from "react"
import { withRouter } from "react-router-dom"
import {
  Avatar,
  Button,
  Card,
  Col,
  Descriptions,
  Divider,
  Form,
  Image,
  message,
  Row,
  Modal,
  Select,
  Popconfirm,
} from "antd"
import {
  ScheduleOutlined,
  QuestionCircleOutlined,
  CheckCircleTwoTone,
  StopTwoTone,
  DeleteOutlined,
  ClockCircleTwoTone,
} from "@ant-design/icons"
import Moment from "moment"
import { extendMoment } from "moment-range"
import FullCalendar from "@fullcalendar/react"
import timeGridPlugin from "@fullcalendar/timegrid"
import interactionPlugin from "@fullcalendar/interaction"
import momentPlugin from "@fullcalendar/moment"
import styled from "styled-components"
import * as Commons from "common/common"
import { socket } from "common/socket"

const moment = extendMoment(Moment)
moment.locale("ja")

const CalendarWrapper = styled.div`
  .fc .fc-button {
    padding: 0;
    background-color: #fff;
    color: #00bcd4;
    border-radius: 0;
    border: 1px solid #00bcd4;
    vertical-align: bottom;
    margin-bottom: 0.5rem;
  }

  .fc .fc-button-primary:not(:disabled):active:focus,
  .fc .fc-button-primary:not(:disabled).fc-button-active:focus {
    box-shadow: none;
  }

  .fc .fc-button-primary:focus {
    box-shadow: none;
  }

  .fc .fc-button-primary:not(:disabled):active,
  .fc .fc-button-primary:not(:disabled).fc-button-active {
    background-color: #00bcd4;
    border-color: #00bcd4;
  }

  .fc .fc-toolbar-title {
    display: inline-block;
    vertical-align: middle;
    margin-left: 1em;
    margin-right: 1em;
    font-size: 2em;
    font-weight: bold;
    white-space: pre-wrap;
    text-align: center;
  }

  .fc .fc-timeline-header-row-chrono .fc-timeline-slot-frame {
    justify-content: center;
  }

  .fc-datagrid-cell-frame {
    background-color: #00b3c4;
    color: white;
    font-weight: bold;
  }

  .fc .fc-resource-timeline-divider {
    width: 0;
  }

  .fc .fc-datagrid-cell-cushion {
    padding-top: 20px;
    padding-bottom: 20px;
    text-align: center;
  }

  .fc .fc-timeline-overlap-enabled .fc-timeline-lane-frame .fc-timeline-events {
    padding-bottom: 0;
  }

  .fc-CustomPrevMonth-button,
  .fc-CustomNextMonth-button,
  .fc-CustomPrevWeek-button,
  .fc-CustomThisWeek-button,
  .fc-CustomNextWeek-button {
    padding: 0.25rem !important;
    margin: 0.25rem !important;
  }

  .fc-day-sat {
    color: #00c2ff;
  }

  .fc-day-sun {
    color: #c40055;
  }

  .fc .fc-bg-event {
    opacity: 0.7;
    font-weight: bold;
  }

  .fc-timegrid-slot {
    height: 3.5em;
    border-bottom: 0 !important;
  }

  .fc .fc-timegrid-col.fc-day-past {
    background-color: rgba(195, 195, 195, 0.2);
  }

  .fc .fc-timegrid-col.fc-day-today {
    background-color: rgba(255, 220, 40, 0.05);
    background-color: var(--fc-today-bg-color, rgba(255, 220, 40, 0.05));
  }

  .fc-timegrid-event {
    border-radius: 0;
  }

  .fc-direction-ltr .fc-timegrid-col-events {
    margin: 0;
  }
`

const Schedule = props => {
  const { history, showLoadingPageSpin, hideLoadingPageSpin } = props
  const calendarRef = useRef()
  const scheduleManageCalendarRef = useRef()
  const isMountedRef = Commons.useIsMountedRef()
  const { Option } = Select
  const [customerScheduleCreateForm] = Form.useForm()

  const [detailModalVisible, setDetailModalVisible] = useState(false)
  const [
    customerScheduleCreateModalVisible,
    setCustomerScheduleCreateModalVisible,
  ] = useState(false)
  const [scheduleManageModalVisible, setScheduleManageModalVisible] = useState(
    false,
  )
  const [loadingButtonSpin, setLoadingButtonSpin] = useState(false)
  const [appointments, setAppointments] = useState([])
  const [qualifiers, setQualifiers] = useState([])
  const [selectedAppointment, setSelectedAppointment] = useState({})
  const [selectedSlot, setSelectedSlot] = useState({})

  const fetchAppointmentsData = useCallback(() => {
    if (isMountedRef.current) {
      showLoadingPageSpin()
    }

    const params = {
      params: {
        from: moment()
          .subtract(1, "month")
          .startOf("month")
          .format("YYYY-MM-DD"),
        to: moment()
          .add(1, "month")
          .endOf("month")
          .format("YYYY-MM-DD"),
      },
    }

    Commons.axiosInstance
      .get(Commons.apiAppointments, params)
      .then(response => {
        if (isMountedRef.current && response && response.data) {
          setAppointments(response.data || [])
        }
      })
      .catch(error => {
        if (error.response.status === 403) {
          message.warning(Commons.errorSessionMsg)
          history.push(Commons.loginURL)
        } else if (error.response.status === 500) {
          message.error(Commons.errorSystemMsg)
        }
      })
      .finally(() => {
        if (isMountedRef.current) {
          hideLoadingPageSpin()
        }
      })
  }, [isMountedRef, history, showLoadingPageSpin, hideLoadingPageSpin])

  const fetchQualifiersData = useCallback(() => {
    if (isMountedRef.current) {
      showLoadingPageSpin()
    }

    Commons.axiosInstance
      .get(Commons.apiQualifiers)
      .then(response => {
        if (isMountedRef.current && response && response.data) {
          setQualifiers(response.data || [])
        }
      })
      .catch(error => {
        if (error.response.status === 403) {
          message.warning(Commons.errorSessionMsg)
          history.push(Commons.loginURL)
        } else if (error.response.status === 500) {
          message.error(Commons.errorSystemMsg)
        }
      })
      .finally(() => {
        if (isMountedRef.current) {
          hideLoadingPageSpin()
        }
      })
  }, [isMountedRef, history, showLoadingPageSpin, hideLoadingPageSpin])

  useEffect(fetchAppointmentsData, [])
  useEffect(fetchQualifiersData, [])
  useEffect(() => {
    socket.on("appointmentUpdate", response => {
      if (response !== undefined && Object.keys(response).length !== 0) {
        if (response.appointment) {
          const updateIds = response.appointment.appointments.map(
            rApp => rApp.appointmentId,
          )

          if (response.appointment.mode === "add") {
            setAppointments(
              response.appointment.appointments.concat(
                appointments.filter(
                  cApp => !updateIds.includes(cApp.appointmentId),
                ),
              ),
            )
          } else if (response.appointment.mode === "remove") {
            setAppointments(
              appointments.filter(
                cApp => !updateIds.includes(cApp.appointmentId),
              ),
            )
          }
        }

        if (response.qualifier) {
          if (response.qualifier.customerId) {
            if (response.qualifier.mode === "add") {
              setQualifiers(qualifiers => [
                {
                  customerId: response.qualifier.customerId,
                  displayName: response.qualifier.displayName,
                  picUrl: response.qualifier.picUrl,
                },
                ...qualifiers.filter(
                  qualifier =>
                    qualifier.customerId !== response.qualifier.customerId,
                ),
              ])
            } else if (response.qualifier.mode === "remove") {
              setQualifiers(
                qualifiers.filter(
                  qualifier =>
                    qualifier.customerId !== response.qualifier.customerId,
                ),
              )
            }
          }
        }
      }
    })

    return () => {
      socket.off("appointmentUpdate")
    }
  }, [isMountedRef, appointments, qualifiers])

  const showLoadingButtonSpin = () => {
    setLoadingButtonSpin(true)
  }

  const hideLoadingButtonSpin = () => {
    setLoadingButtonSpin(false)
  }

  const scheduleCreate = (start, end) => {
    if (isMountedRef.current) {
      const dateRange = moment.range(start, end)
      const dateChunk = Array.from(
        dateRange.by("hours", { step: 1, excludeEnd: true }),
      )

      showLoadingButtonSpin()

      const paramData = {
        times: dateChunk,
      }

      Commons.axiosInstance
        .post(Commons.apiAppointments + "/schedule", paramData)
        .then(response => {
          if (response.status === 200) {
            message.success(Commons.successCreateMsg)
            fetchAppointmentsData()
          }
        })
        .catch(error => {
          if (error.response.status === 403) {
            message.warning(Commons.errorSessionMsg)
            history.push(Commons.loginURL)
          } else if (error.response.status === 500) {
            message.error(Commons.errorSystemMsg)
          }
        })
        .finally(() => {
          if (isMountedRef.current) {
            hideLoadingButtonSpin()
          }
        })
    }
  }

  const customerScheduleCreateOnFinish = data => {
    if (isMountedRef.current) {
      showLoadingButtonSpin()

      const paramData = {
        customerId: data.customerScheduleCreateFormItem,
      }

      Commons.axiosInstance
        .put(
          Commons.apiAppointments + "/" + selectedSlot.appointmentId,
          paramData,
        )
        .then(response => {
          if (response.status === 200) {
            message.success(Commons.successCreateMsg)
            fetchAppointmentsData()
            fetchQualifiersData()
            hideCustomerScheduleCreateModal()
          }
        })
        .catch(error => {
          if (error.response.status === 403) {
            message.warning(Commons.errorSessionMsg)
            history.push(Commons.loginURL)
          } else if (error.response.status === 204) {
            message.warning(Commons.errorScheduleExistMsg)
            fetchAppointmentsData()
            fetchQualifiersData()
            hideCustomerScheduleCreateModal()
          } else if (error.response.status === 500) {
            message.error(Commons.errorSystemMsg)
          }
        })
        .finally(() => {
          if (isMountedRef.current) {
            hideLoadingButtonSpin()
          }
        })
    }
  }

  const customerScheduleDelete = appointmentId => {
    if (isMountedRef.current) {
      showLoadingButtonSpin()

      Commons.axiosInstance
        .put(Commons.apiAppointments + "/" + appointmentId)
        .then(response => {
          if (response.status === 200) {
            message.success(Commons.successCreateMsg)
            fetchAppointmentsData()
            fetchQualifiersData()
            hideDetailModal()
          }
        })
        .catch(error => {
          if (error.response.status === 403) {
            message.warning(Commons.errorSessionMsg)
            history.push(Commons.loginURL)
          } else if (error.response.status === 500) {
            message.error(Commons.errorSystemMsg)
          }
        })
        .finally(() => {
          if (isMountedRef.current) {
            hideLoadingButtonSpin()
          }
        })
    }
  }

  const scheduleDelete = appointmentId => {
    if (isMountedRef.current) {
      Commons.axiosInstance
        .delete(Commons.apiAppointments + "/" + appointmentId)
        .then(response => {
          if (response.status === 200) {
            message.success(Commons.successDeleteMsg)
            fetchAppointmentsData()
          }
        })
        .catch(error => {
          if (error.response.status === 403) {
            message.warning(Commons.errorSessionMsg)
            history.push(Commons.loginURL)
          } else if (error.response.status === 500) {
            message.error(Commons.errorSystemMsg)
          }
        })
        .finally(() => {
          if (isMountedRef.current) {
            hideLoadingButtonSpin()
          }
        })
    }
  }

  const showDetailModal = (eventData, start) => {
    setSelectedAppointment(eventData)
    setDetailModalVisible(true)
  }

  const hideDetailModal = () => {
    setDetailModalVisible(false)
    setSelectedAppointment({})
  }

  const showCustomerScheduleCreateModal = (appointmentId, date) => {
    setSelectedSlot({ start: date, appointmentId: appointmentId })
    setCustomerScheduleCreateModalVisible(true)
  }

  const hideCustomerScheduleCreateModal = () => {
    setCustomerScheduleCreateModalVisible(false)
    setSelectedSlot({})
    customerScheduleCreateForm.resetFields()
  }

  const showScheduleManageModal = () => {
    setScheduleManageModalVisible(true)
  }

  const hideScheduleManageModal = () => {
    setScheduleManageModalVisible(false)
  }

  return (
    <Card title="予約管理" bordered={false} className="h-full">
      <Card
        title="面接予約"
        bordered={true}
        type="inner"
        className="h-full"
        headStyle={{
          color: "#FFF",
          backgroundColor: "#3dbaeb",
        }}
        style={{
          borderColor: "#3dbaeb",
        }}
        extra={
          <Button
            className="m-1"
            style={{ color: "#3dbaeb" }}
            icon={<ScheduleOutlined />}
            onClick={showScheduleManageModal}
          >
            面接時間設定
          </Button>
        }
      >
        <CalendarWrapper>
          <FullCalendar
            locale="ja"
            ref={calendarRef}
            plugins={[timeGridPlugin, interactionPlugin, momentPlugin]}
            initialView="timeGridWeek"
            height="64.7vh"
            customButtons={{
              CustomPrevMonth: {
                // icon: "chevron-left",
                text: "先月",
                click: function() {
                  calendarRef.current.getApi().gotoDate(
                    moment(calendarRef.current.getApi().getDate())
                      .subtract(1, "month")
                      .toDate(),
                  )
                },
              },
              CustomNextMonth: {
                // icon: "chevron-right",
                text: "来月",
                click: function() {
                  calendarRef.current.getApi().gotoDate(
                    moment(calendarRef.current.getApi().getDate())
                      .add(1, "month")
                      .toDate(),
                  )
                },
              },
              CustomPrevWeek: {
                text: "先週",
                click: function() {
                  calendarRef.current.getApi().prev()
                },
              },
              CustomThisWeek: {
                text: "今週",
                click: function() {
                  calendarRef.current.getApi().today()
                },
              },
              CustomNextWeek: {
                text: "来週",
                click: function() {
                  calendarRef.current.getApi().next()
                },
              },
            }}
            headerToolbar={{
              left: "CustomPrevMonth,CustomNextMonth",
              center: "title",
              right: "CustomPrevWeek,CustomThisWeek,CustomNextWeek",
            }}
            titleFormat={date => {
              return moment(date.date).format("YYYY年M月")
            }}
            firstDay={1}
            businessHours={{
              daysOfWeek: [1, 2, 3, 4, 5, 6, 0],
              startTime: Commons.BOOKING_OPEN_TIME,
              endTime: Commons.BOOKING_CLOSE_TIME,
            }}
            allDaySlot={false}
            slotLabelFormat={{
              hour: "2-digit",
              minute: "2-digit",
              omitZeroMinute: false,
            }}
            slotDuration="01:00"
            slotMinTime={Commons.BUSINESS_OPEN_TIME}
            slotMaxTime={Commons.BUSINESS_CLOSE_TIME}
            slotLabelInterval={Commons.BUSINESS_INTERVAL_TIME}
            dayHeaderFormat={date => {
              return moment(date.date).format("D（ddd）")
            }}
            eventColor="#FFFFEF"
            eventBorderColor="#00bcd4"
            eventTextColor="#000000"
            eventTimeFormat={{
              hour: "2-digit",
              minute: "2-digit",
              meridiem: false,
            }}
            slotEventOverlap={false}
            displayEventTime={true}
            displayEventEnd={false}
            nowIndicator={true}
            events={appointments.map(appointment => {
              if (appointment.customerId && appointment.Customer) {
                return {
                  groupId: "event",
                  appointmentId: appointment.appointmentId,
                  customerId: appointment.customerId,
                  customer: appointment.Customer,
                  title: appointment.Customer.displayName || "",
                  start: appointment.startAt,
                  end: moment(appointment.startAt).add(55, "minutes"),
                }
              } else {
                return {
                  groupId: "background",
                  display: "background",
                  backgroundColor: moment(appointment.startAt).isSameOrAfter(
                    moment(),
                  )
                    ? "#d9f7be"
                    : "#ffe7ba",
                  appointmentId: appointment.appointmentId,
                  start: appointment.startAt,
                  end: moment(appointment.startAt).add(55, "minutes"),
                }
              }
            })}
            eventContent={arg => {
              if (arg.event.groupId === "event") {
                return (
                  <div className="px-2 py-1 truncate">
                    <span style={{ fontWeight: "bold" }}>
                      {moment(arg.event.start).format("HH:mm")}
                    </span>
                    <br />
                    <span
                      style={{
                        color: "#00bcd4",
                        fontWeight: "bold",
                      }}
                    >
                      {arg.event.title}
                    </span>
                  </div>
                )
              } else if (arg.event.groupId === "background") {
                if (moment(arg.event.start).isSameOrAfter(moment())) {
                  return (
                    <Row justify="center" className="py-3 cursor-pointer">
                      <Col>
                        <CheckCircleTwoTone
                          twoToneColor="#52c41a"
                          className="text-2xl"
                        />
                      </Col>
                    </Row>
                  )
                } else {
                  return (
                    <Row justify="center" className="py-3 cursor-pointer">
                      <Col>
                        <StopTwoTone
                          twoToneColor="#fa8c16"
                          className="text-2xl"
                        />
                      </Col>
                    </Row>
                  )
                }
              }
            }}
            eventClick={eventInfo => {
              if (eventInfo.event.groupId === "event") {
                showDetailModal({
                  ...eventInfo.event.extendedProps,
                  startAt: eventInfo.event.start,
                })
              } else if (eventInfo.event.groupId === "background") {
                if (moment(eventInfo.event.start).isSameOrAfter(moment())) {
                  showCustomerScheduleCreateModal(
                    eventInfo.event.extendedProps.appointmentId,
                    eventInfo.event.start,
                  )
                }
              }
            }}
          />
        </CalendarWrapper>
      </Card>
      <Modal
        title=""
        visible={detailModalVisible}
        onCancel={hideDetailModal}
        footer={null}
        centered
      >
        <Row className="mt-2" gutter={[0, 16]}>
          <Col span={24}>
            <Descriptions title="面接予約詳細" bordered column={1}>
              <Descriptions.Item label="写真">
                {selectedAppointment.customer?.picUrl ? (
                  <Image
                    width={60}
                    src={Commons.mediaURL + selectedAppointment.customer.picUrl}
                  />
                ) : (
                  <Image width={60} src="no-image.png" />
                )}
              </Descriptions.Item>
              <Descriptions.Item label="お名前">
                {selectedAppointment.customer?.displayName || ""}
              </Descriptions.Item>
              <Descriptions.Item label="年齢">
                {selectedAppointment.customer?.age + "歳" || ""}
              </Descriptions.Item>
              <Descriptions.Item label="体型">
                {selectedAppointment.customer?.btype || ""}
              </Descriptions.Item>
              <Descriptions.Item label="住所">
                {selectedAppointment.customer?.address || ""}
              </Descriptions.Item>
              <Descriptions.Item label="情報源">
                {selectedAppointment.customer?.source || ""}
              </Descriptions.Item>
              <Descriptions.Item label="備考">
                {selectedAppointment.customer?.remarks || ""}
              </Descriptions.Item>
              <Descriptions.Item label="面接日">
                {selectedAppointment.startAt
                  ? moment(selectedAppointment.startAt).format("YYYY/MM/DD")
                  : ""}
              </Descriptions.Item>
              <Descriptions.Item label="面接時間">
                {selectedAppointment.startAt
                  ? moment(selectedAppointment.startAt).format("HH:mm")
                  : ""}
              </Descriptions.Item>
            </Descriptions>
          </Col>
          <Col span={24} className="text-center">
            <Popconfirm
              title="予約をキャンセルしますか？"
              onConfirm={() =>
                customerScheduleDelete(selectedAppointment.appointmentId)
              }
              okText="キャンセル"
              cancelText="閉じる"
              okType="danger"
              icon={<QuestionCircleOutlined style={{ color: "red" }} />}
            >
              <Button
                style={{ margin: "0.25rem" }}
                icon={<DeleteOutlined />}
                danger
              >
                キャンセル
              </Button>
            </Popconfirm>
          </Col>
        </Row>
      </Modal>
      <Modal
        title=""
        visible={customerScheduleCreateModalVisible}
        onCancel={hideCustomerScheduleCreateModal}
        footer={null}
        centered
      >
        <Row className="mt-2" gutter={[0, 16]}>
          <Col span={24}>
            <Descriptions title="新規面接" bordered column={1}>
              <Descriptions.Item label="面接日">
                {moment(selectedSlot.start).format("YYYY/MM/DD")}
              </Descriptions.Item>
              <Descriptions.Item label="面接時間">
                {moment(selectedSlot.start).format("HH:mm")}
              </Descriptions.Item>
            </Descriptions>
          </Col>
          <Col span={24}>
            <Form
              form={customerScheduleCreateForm}
              name="customerScheduleCreateForm"
              onFinish={customerScheduleCreateOnFinish}
            >
              <Row gutter={[0, 16]}>
                <Col span={24}>
                  <Form.Item
                    name="customerScheduleCreateFormItem"
                    rules={[
                      {
                        required: true,
                        message: "申込者を選択してください",
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      placeholder="申込者を選択してください"
                      optionFilterProp="children"
                    >
                      {qualifiers.length > 0
                        ? qualifiers.map(qualifier => (
                            <Option
                              key={qualifier.customerId}
                              value={qualifier.customerId}
                            >
                              {qualifier.picUrl ? (
                                <Avatar
                                  size="small"
                                  src={Commons.mediaURL + qualifier.picUrl}
                                />
                              ) : (
                                <Avatar size="small" src="no-image.png" />
                              )}
                              <Divider type="vertical" />
                              {qualifier.displayName}
                            </Option>
                          ))
                        : ""}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    className="text-center"
                    style={{ marginBottom: 0 }}
                  >
                    <Button
                      type="primary"
                      htmlType="submit"
                      loading={loadingButtonSpin}
                    >
                      登録
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </Modal>
      <Modal
        title="利用可能な面接時間"
        visible={scheduleManageModalVisible}
        onCancel={hideScheduleManageModal}
        footer={null}
        centered
      >
        <CalendarWrapper>
          <FullCalendar
            locale="ja"
            ref={scheduleManageCalendarRef}
            plugins={[timeGridPlugin, interactionPlugin, momentPlugin]}
            initialView="timeGridWeek"
            height="64.7vh"
            customButtons={{
              CustomPrevMonth: {
                // icon: "chevron-left",
                text: "先月",
                click: function() {
                  scheduleManageCalendarRef.current.getApi().gotoDate(
                    moment(scheduleManageCalendarRef.current.getApi().getDate())
                      .subtract(1, "month")
                      .toDate(),
                  )
                },
              },
              CustomNextMonth: {
                // icon: "chevron-right",
                text: "来月",
                click: function() {
                  scheduleManageCalendarRef.current.getApi().gotoDate(
                    moment(scheduleManageCalendarRef.current.getApi().getDate())
                      .add(1, "month")
                      .toDate(),
                  )
                },
              },
              CustomPrevWeek: {
                text: "先週",
                click: function() {
                  scheduleManageCalendarRef.current.getApi().prev()
                },
              },
              CustomThisWeek: {
                text: "今週",
                click: function() {
                  scheduleManageCalendarRef.current.getApi().today()
                },
              },
              CustomNextWeek: {
                text: "来週",
                click: function() {
                  scheduleManageCalendarRef.current.getApi().next()
                },
              },
            }}
            headerToolbar={{
              left: "CustomPrevMonth,CustomNextMonth",
              center: "title",
              right: "CustomPrevWeek,CustomThisWeek,CustomNextWeek",
            }}
            titleFormat={date => {
              return moment(date.date).format("YYYY年M月")
            }}
            firstDay={1}
            businessHours={{
              daysOfWeek: [1, 2, 3, 4, 5, 6, 0],
              startTime: Commons.BOOKING_OPEN_TIME,
              endTime: Commons.BOOKING_CLOSE_TIME,
            }}
            allDaySlot={false}
            slotLabelFormat={{
              hour: "2-digit",
              minute: "2-digit",
              omitZeroMinute: false,
            }}
            slotDuration="01:00"
            slotMinTime={Commons.BUSINESS_OPEN_TIME}
            slotMaxTime={Commons.BUSINESS_CLOSE_TIME}
            slotLabelInterval={Commons.BUSINESS_INTERVAL_TIME}
            dayHeaderFormat={date => {
              return moment(date.date).format("D（ddd）")
            }}
            eventTimeFormat={{
              hour: "2-digit",
              minute: "2-digit",
              meridiem: false,
            }}
            slotEventOverlap={false}
            displayEventTime={true}
            displayEventEnd={false}
            nowIndicator={true}
            selectable={true}
            selectOverlap={false}
            unselectAuto={true}
            selectAllow={selectInfo => moment().isBefore(selectInfo.start)}
            selectConstraint="businessHours"
            events={appointments.map(appointment => {
              if (appointment.customerId && appointment.Customer) {
                return {
                  groupId: "event",
                  backgroundColor: "#ffd8bf",
                  borderColor: "#ffd8bf",
                  appointmentId: appointment.appointmentId,
                  start: appointment.startAt,
                  end: moment(appointment.startAt).add(55, "minutes"),
                }
              } else {
                return {
                  groupId: "background",
                  display: "background",
                  backgroundColor: "#bae7ff",
                  appointmentId: appointment.appointmentId,
                  start: appointment.startAt,
                  end: moment(appointment.startAt).add(55, "minutes"),
                }
              }
            })}
            eventContent={arg => {
              if (arg.event.groupId === "event") {
                return (
                  <Row justify="center" className="py-3 cursor-not-allowed">
                    <Col>
                      <StopTwoTone
                        twoToneColor="#fa8c16"
                        className="text-2xl"
                      />
                    </Col>
                  </Row>
                )
              } else if (arg.event.groupId === "background") {
                return (
                  <Row justify="center" className="py-3 cursor-pointer">
                    <Col>
                      <ClockCircleTwoTone
                        twoToneColor="#1890ff"
                        className="text-2xl"
                      />
                    </Col>
                  </Row>
                )
              }
            }}
            eventClick={eventInfo => {
              if (eventInfo.event.groupId === "background") {
                Modal.confirm({
                  title: "利用可能な面接時間削除",
                  icon: <QuestionCircleOutlined style={{ color: "#f5222d" }} />,
                  content: `${moment(eventInfo.event.start).format(
                    "YYYY/MM/DD HH:mm",
                  )}`,
                  okText: "削除",
                  okButtonProps: { loading: loadingButtonSpin, danger: true },
                  onOk: () => {
                    scheduleDelete(eventInfo.event.extendedProps.appointmentId)
                  },
                  cancelText: "キャンセル",
                })
              }
            }}
            select={selectInfo => {
              Modal.confirm({
                title: "利用可能な面接時間登録",
                icon: <QuestionCircleOutlined style={{ color: "#1890ff" }} />,
                content: `${moment(selectInfo.start).format(
                  "YYYY/MM/DD HH:mm",
                )} ～ ${moment(selectInfo.end).format("YYYY/MM/DD HH:mm")}`,
                okText: "登録",
                okButtonProps: { loading: loadingButtonSpin },
                onOk: () => {
                  scheduleCreate(selectInfo.start, selectInfo.end)
                },
                cancelText: "キャンセル",
              })
            }}
          />
        </CalendarWrapper>
      </Modal>
    </Card>
  )
}

export default withRouter(Schedule)
