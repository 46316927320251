import React, { useRef, useState, useEffect, useCallback } from "react"
import { withRouter } from "react-router-dom"
import {
  Avatar,
  Alert,
  Badge,
  Button,
  Card,
  Checkbox,
  Col,
  Form,
  Image,
  Input,
  InputNumber,
  List,
  message,
  Modal,
  notification,
  Popconfirm,
  Space,
  Select,
  Switch,
  Table,
  Tag,
  Tooltip,
  Radio,
  Row,
} from "antd"
import {
  CommentOutlined,
  DeleteOutlined,
  IdcardOutlined,
  QuestionCircleOutlined,
  CloseSquareTwoTone,
  CheckSquareTwoTone,
  UserOutlined,
  CheckOutlined,
  MinusOutlined,
  CloseOutlined,
  PhoneOutlined,
  MessageOutlined,
  SendOutlined,
  PlusOutlined,
  EditOutlined,
  PhoneFilled,
  BellFilled,
  BellOutlined,
} from "@ant-design/icons"
import moment from "moment"
import "moment/locale/ja"
import { socket } from "common/socket"
import styled from "styled-components"
import * as Commons from "common/common"

moment.locale("ja")

const CustomTextArea = styled(Input.TextArea)`
  .ant-input {
    border: 1px solid #3dbaeb;
    border-radius: 0.25rem;
    padding: 1rem;
  }

  .ant-input-affix-wrapper {
    border-radius: 0.25rem;
  }

  &::after {
    color: #3dbaeb;
  }
`

const CustomBadge = styled(Badge)`
  .ant-badge-status-text {
    margin: 0;
  }
`

const CustomRadioGreyButton = styled(Radio.Button)`
  &.ant-radio-button-wrapper:not(:first-child)::before {
    background-color: #bfbfbf;
  }

  &.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover::before {
    background-color: #d9d9d9;
  }
`

const CustomRadioGreenButton = styled(Radio.Button)`
  &.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
    background-color: #73d13d;
  }

  &.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover::before {
    background-color: #73d13d;
  }
`

const CustomSwitch = styled(Switch)`
  &.ant-switch-checked {
    background-color: #fa541c;
  }
`

const Home = props => {
  const { history, showLoadingPageSpin, hideLoadingPageSpin } = props
  const isMountedRef = Commons.useIsMountedRef()
  const messageSend = useRef(null)
  const [form] = Form.useForm()
  const [lineForm] = Form.useForm()
  const [noteForm] = Form.useForm()

  const [pagination, setPagination] = useState({
    perPage: 20,
    page: 1,
    total: 0,
    sort: "desc",
    sortKey: "updatedAt",
  })
  const [registrations, setRegistrations] = useState([])
  const [chats, setChats] = useState([])
  const [faqTemplate, setFaqTemplate] = useState([])
  const [currentUser, setCurrentUser] = useState(false)
  const [faqModalVisible, setFaqModalVisible] = useState(false)
  const [lineModalVisible, setLineModalVisible] = useState(false)
  const [isNoteModalVisible, setIsNoteModalVisible] = useState(false)
  const [loadingSendSpin, setLoadingSendSpin] = useState(false)
  const [loadingSwitchSpin, setLoadingSwitchSpin] = useState(false)

  const BODY_TYPE = ["細身", "スリム", "普通", "グラマー", "太め"]
  const STATUS = ["不合格", "待機", "合格"]
  const { Option } = Select

  const statusOnChange = (id, stage) => {
    showLoadingPageSpin()

    const putData = {
      iStage: stage,
    }

    Commons.axiosInstance
      .put(Commons.apiRegistrations + "/" + id, putData)
      .then(response => {
        if (isMountedRef.current && response && response.status === 200) {
          message.success(Commons.successUpdateMsg)
        }
      })
      .catch(error => {
        if (error.response.status === 403) {
          message.warning(Commons.errorSessionMsg)
          history.push(Commons.loginURL)
        } else if (error.response.status === 500) {
          message.error(Commons.errorSystemMsg)
        }
      })
      .finally(() => {
        if (isMountedRef.current) {
          hideLoadingPageSpin()
        }
      })
  }

  const handleIdRequest = (id, name) => {
    showLoadingSendSpin()

    const paramData = {
      customerId: id,
    }

    Commons.axiosInstance
      .post(Commons.apiCertificate, paramData)
      .then(response => {
        if (isMountedRef.current && response && response.status === 200) {
          message.success(Commons.successUpdateMsg)
          notification.open({
            message: "身分証明書リクエスト",
            description: name
              ? `「${name}さん」への身分証明書リクエストが成功しました`
              : "身分証明書リクエストが成功しました",
            icon: <IdcardOutlined style={{ color: "#52c41a" }} />,
            placement: "bottomRight",
            duration: 10,
          })
        }
      })
      .catch(error => {
        if (error.response.status === 403) {
          message.warning(Commons.errorSessionMsg)
          history.push(Commons.loginURL)
        } else if (error.response.status === 500) {
          notification.open({
            message: Commons.errorSystemMsg,
            description: "",
            icon: <PhoneOutlined style={{ color: "#52c41a" }} />,
            placement: "bottomRight",
          })
        }
      })
      .finally(() => {
        if (isMountedRef.current) {
          hideLoadingSendSpin()
        }
      })
  }

  const handleInterviewReNotify = (id, name) => {
    showLoadingSendSpin()

    Commons.axiosInstance
      .put(Commons.apiRegistrations + "/schedule/" + id)
      .then(response => {
        if (isMountedRef.current && response && response.status === 200) {
          message.success(Commons.successSentMsg)
          // notification.open({
          //   message: Commons.NOTIFY_TITLE,
          //   description: name
          //     ? `「${name}さん」に${Commons.NOTIFY_TITLE}を送信しました`
          //     : Commons.NOTIFY_MSG,
          //   icon: <BellOutlined style={{ color: "#52c41a" }} />,
          //   placement: "bottomRight",
          //   duration: 10,
          // })
        }
      })
      .catch(error => {
        if (error.response.status === 403) {
          message.warning(Commons.errorSessionMsg)
          history.push(Commons.loginURL)
        } else if (error.response.status === 500) {
          message.error(Commons.errorSystemMsg)
        }
      })
      .finally(() => {
        if (isMountedRef.current) {
          hideLoadingSendSpin()
        }
      })
  }

  const handleCompleteRequest = id => {
    showLoadingSendSpin()

    Commons.axiosInstance
      .put(Commons.apiRegistrations + "/complete/" + id)
      .then(response => {
        if (isMountedRef.current && response && response.status === 200) {
          message.success(Commons.successUpdateMsg)
        }
      })
      .catch(error => {
        if (error.response.status === 403) {
          message.warning(Commons.errorSessionMsg)
          history.push(Commons.loginURL)
        } else if (error.response.status === 500) {
          message.error(Commons.errorSystemMsg)
        }
      })
      .finally(() => {
        if (isMountedRef.current) {
          hideLoadingSendSpin()
        }
      })
  }

  const certificateOnChange = (id, isAccepted) => {
    showLoadingPageSpin()

    const putData = {
      isAccepted: isAccepted,
    }

    Commons.axiosInstance
      .put(Commons.apiCertificate + "/" + id, putData)
      .then(response => {
        if (isMountedRef.current && response && response.status === 200) {
          message.success(Commons.successUpdateMsg)
        }
      })
      .catch(error => {
        if (error.response.status === 403) {
          message.warning(Commons.errorSessionMsg)
          history.push(Commons.loginURL)
        } else if (error.response.status === 500) {
          message.error(Commons.errorSystemMsg)
        }
      })
      .finally(() => {
        if (isMountedRef.current) {
          hideLoadingPageSpin()
        }
      })
  }

  const handleOnHoldChange = (id, checked) => {
    showLoadingSwitchSpin()

    const putData = {
      isOnHold: checked,
    }

    Commons.axiosInstance
      .put(Commons.apiRegistrations + "/hold/" + id, putData)
      .then(response => {
        if (isMountedRef.current && response && response.status === 200) {
          message.success(Commons.successUpdateMsg)
        }
      })
      .catch(error => {
        if (error.response.status === 403) {
          message.warning(Commons.errorSessionMsg)
          history.push(Commons.loginURL)
        } else if (error.response.status === 500) {
          message.error(Commons.errorSystemMsg)
        }
      })
      .finally(() => {
        if (isMountedRef.current) {
          hideLoadingSwitchSpin()
        }
      })
  }

  const columns = [
    {
      title: "ID",
      dataIndex: "customerId",
      align: "center",
      sorter: () => {},
    },
    {
      title: "備考",
      dataIndex: "remarks",
      align: "center",
      render: (r, record) =>
        r.remarks ? (
          <Tag
            style={{ fontSize: "14px", backgroundColor: "#fff7e6" }}
            className="m-1 p-2"
          >
            {r.remarks}
            <Button
              size="small"
              onClick={() => showNoteModal(r.remarks, r.customerId)}
              className="m-1"
              icon={<EditOutlined />}
            />
          </Tag>
        ) : (
          <Button
            onClick={() => showNoteModal("", r.customerId)}
            className="m-1"
            icon={<PlusOutlined />}
          >
            備考追加
          </Button>
        ),
    },
    {
      title: "写真",
      dataIndex: "picUrl",
      align: "center",
      render: (text, record) =>
        text !== "" && text !== null ? (
          <Image width={60} src={Commons.mediaURL + text} />
        ) : (
          <Image width={60} src="no-image.png" />
        ),
    },
    {
      title: "お名前",
      dataIndex: "displayName",
      align: "center",
      width: 130,
      sorter: () => {},
    },
    {
      title: "年齢",
      dataIndex: "age",
      align: "center",
      render: (text, record) =>
        text !== null
          ? text === 19
            ? text + "以下"
            : text === 31
            ? text + "以上"
            : text + "歳"
          : "",
      sorter: () => {},
    },
    {
      title: "面接保留",
      dataIndex: "isOnHold",
      align: "center",
      render: (r, record) => (
        <Tooltip title="面接保留" placement="top">
          <CustomSwitch
            checked={r.isOnHold}
            onChange={(checked, event) =>
              handleOnHoldChange(r.customerId, checked)
            }
            loading={loadingSwitchSpin}
          />
        </Tooltip>
      ),
    },
    {
      title: "面接状態",
      dataIndex: "status",
      align: "center",
      width: 150,
      render: (text, record) =>
        text.registrationStage >= 8 ? (
          <div
            style={{
              border:
                text.registrationStage === 8 && text.interviewStage === 1
                  ? "dashed 1px #3dbaeb"
                  : "",
            }}
          >
            {text.registrationStage === 8 && text.interviewStage === 1 ? (
              <Row justify="center">
                <Col>
                  <CustomBadge status="processing" />
                </Col>
              </Row>
            ) : (
              ""
            )}
            <Radio.Group
              defaultValue={text.interviewStage}
              value={text.interviewStage}
              buttonStyle="solid"
            >
              {STATUS.map((s, i) =>
                i === 0 ? (
                  text.interviewStage !== i ? (
                    text.registrationStage === 8 ? (
                      <Popconfirm
                        title="面接状態を不合格に変更してもよろしいですか?"
                        onConfirm={() => statusOnChange(text.customerId, i)}
                        okText="確認"
                        cancelText="閉じる"
                        key={i}
                      >
                        <Tooltip title="不合格" placement="left">
                          <Radio.Button
                            style={{
                              borderColor: "#bfbfbf",
                              background: "#d9d9d9",
                            }}
                            className="m-1"
                            value={i}
                          >
                            <CloseOutlined
                              style={{
                                color: "#FFF",
                              }}
                            />
                          </Radio.Button>
                        </Tooltip>
                      </Popconfirm>
                    ) : (
                      <Tooltip title="不合格" placement="left" key={i}>
                        <Radio.Button
                          style={{
                            borderColor: "#bfbfbf",
                            background: "#d9d9d9",
                          }}
                          className="m-1"
                          value={i}
                        >
                          <CloseOutlined
                            style={{
                              color: "#FFF",
                            }}
                          />
                        </Radio.Button>
                      </Tooltip>
                    )
                  ) : (
                    <Tooltip title="不合格" placement="left" key={i}>
                      <Radio.Button
                        style={{
                          borderColor: "#ff4d4f",
                          background: "#ff7875",
                        }}
                        className="m-1"
                        value={i}
                      >
                        <CloseOutlined
                          style={{
                            color: "#FFF",
                          }}
                        />
                      </Radio.Button>
                    </Tooltip>
                  )
                ) : i === 2 ? (
                  text.interviewStage !== i ? (
                    text.registrationStage === 8 ? (
                      <Popconfirm
                        title="面接状態を合格に変更してもよろしいですか?"
                        onConfirm={() => statusOnChange(text.customerId, i)}
                        okText="確認"
                        cancelText="閉じる"
                        key={i}
                      >
                        <Tooltip title="合格" placement="right">
                          <CustomRadioGreyButton
                            style={{
                              borderColor: "#bfbfbf",
                              background: "#d9d9d9",
                            }}
                            className="m-1"
                            value={i}
                          >
                            <CheckOutlined
                              style={{
                                color: "#FFF",
                              }}
                            />
                          </CustomRadioGreyButton>
                        </Tooltip>
                      </Popconfirm>
                    ) : (
                      <Tooltip title="合格" placement="right" key={i}>
                        <CustomRadioGreyButton
                          style={{
                            borderColor: "#bfbfbf",
                            background: "#d9d9d9",
                          }}
                          className="m-1"
                          value={i}
                        >
                          <CheckOutlined
                            style={{
                              color: "#FFF",
                            }}
                          />
                        </CustomRadioGreyButton>
                      </Tooltip>
                    )
                  ) : (
                    <Tooltip title="合格" placement="right" key={i}>
                      <CustomRadioGreenButton
                        style={{
                          borderColor: "#73d13d",
                          background: "#95de64",
                        }}
                        className="m-1"
                        value={i}
                        key={i}
                      >
                        <CheckOutlined
                          style={{
                            color: "#FFF",
                          }}
                        />
                      </CustomRadioGreenButton>
                    </Tooltip>
                  )
                ) : (
                  ""
                ),
              )}
            </Radio.Group>
          </div>
        ) : (
          <Radio.Group>
            <Radio.Button disabled style={{ borderColor: "#bfbfbf" }}>
              <MinusOutlined className="m-1" style={{ color: "#bfbfbf" }} />
            </Radio.Button>
          </Radio.Group>
        ),
    },
    {
      title: "面接日",
      dataIndex: "appointment",
      align: "center",
      width: 130,
      render: (registration, record) => (
        <div>
          {registration.Appointment && registration.Appointment.startAt ? (
            <Tag color="blue" className="m-1 p-2 whitespace-pre-wrap">
              {moment(registration.Appointment.startAt).format("YYYY年M月D日")}
              <br />
              {moment(registration.Appointment.startAt).format("HH:mm")}
            </Tag>
          ) : registration.registrationStage === 8 &&
            registration.interviewStage === 2 ? (
            <Popconfirm
              title={`申込者に${Commons.NOTIFY_TITLE}を送信してもよろしいですか?`}
              onConfirm={() =>
                handleInterviewReNotify(
                  registration.customerId,
                  registration.displayName,
                )
              }
              okText="確認"
              cancelText="閉じる"
            >
              <Button className="m-1" type="primary" icon={<BellOutlined />}>
                再通知
              </Button>
            </Popconfirm>
          ) : (
            "ー"
          )}
        </div>
      ),
      sorter: () => {},
    },
    {
      title: "身分証明書",
      dataIndex: "certificate",
      align: "center",
      width: 160,
      render: (text, record) => (
        <div
          style={{
            border:
              text.registrationStage >= 8 &&
              text.registrationStage < 10 &&
              text.interviewStage === 2
                ? "dashed 1px #3dbaeb"
                : "",
          }}
        >
          {text.registrationStage >= 8 &&
          text.registrationStage < 10 &&
          text.interviewStage === 2 ? (
            <Row justify="center">
              <Col>
                <CustomBadge status="processing" />
              </Col>
            </Row>
          ) : (
            ""
          )}
          {text.registrationStage === 8 ? (
            text.interviewStage === 2 ? (
              <Popconfirm
                title="身分証明書をリクエストしてもよろしいですか?"
                onConfirm={() =>
                  handleIdRequest(text.customerId, text.displayName)
                }
                okText="確認"
                cancelText="閉じる"
              >
                <Button
                  className="m-1"
                  type="primary"
                  icon={<IdcardOutlined />}
                >
                  リクエスト
                </Button>
              </Popconfirm>
            ) : (
              <Radio.Group>
                <Radio.Button disabled style={{ borderColor: "#bfbfbf" }}>
                  <MinusOutlined className="m-1" style={{ color: "#bfbfbf" }} />
                </Radio.Button>
              </Radio.Group>
            )
          ) : text.registrationStage === 9 ? (
            text.certBlob && text.certBlob !== null ? (
              <Row>
                <Col span={24}>
                  <Image
                    width={60}
                    className="border border-gray-300"
                    src={`data:image/jpeg;base64,${text.certBlob}`}
                  />
                  {text.certBlob1 && text.certBlob1 !== null ? (
                    <Image
                      width={60}
                      className="ml-2 border border-gray-300"
                      src={`data:image/jpeg;base64,${text.certBlob1}`}
                    />
                  ) : (
                    ""
                  )}
                </Col>
                <Col span={24}>
                  <Radio.Group buttonStyle="solid">
                    <Popconfirm
                      title="身分証明書を却下してもよろしいですか?"
                      onConfirm={() =>
                        certificateOnChange(text.customerId, false)
                      }
                      okText="確認"
                      cancelText="閉じる"
                    >
                      <Tooltip title="却下" placement="left">
                        <Radio.Button
                          style={{
                            borderColor: "#bfbfbf",
                            background: "#d9d9d9",
                            fontSize: "12px",
                            padding: "0 4px",
                            height: "20px",
                            lineHeight: "18px",
                          }}
                          className="m-1"
                          value={0}
                        >
                          <CloseOutlined
                            style={{
                              color: "#FFF",
                            }}
                          />
                        </Radio.Button>
                      </Tooltip>
                    </Popconfirm>
                    <Popconfirm
                      title="身分証明書を承認してもよろしいですか?"
                      onConfirm={() =>
                        certificateOnChange(text.customerId, true)
                      }
                      okText="確認"
                      cancelText="閉じる"
                    >
                      <Tooltip title="承認" placement="right">
                        <CustomRadioGreyButton
                          style={{
                            borderColor: "#bfbfbf",
                            background: "#d9d9d9",
                            fontSize: "12px",
                            padding: "0 4px",
                            height: "20px",
                            lineHeight: "18px",
                          }}
                          className="m-1"
                          value={1}
                        >
                          <CheckOutlined
                            style={{
                              color: "#FFF",
                            }}
                          />
                        </CustomRadioGreyButton>
                      </Tooltip>
                    </Popconfirm>
                  </Radio.Group>
                </Col>
              </Row>
            ) : (
              <Row justify="center" gutter={[0, 8]}>
                <Col span={24} className="text-center">
                  <Alert
                    message="リクエストが送信されました"
                    type="info"
                    style={{ fontSize: "12px" }}
                    className="mx-1"
                  />
                </Col>
                <Col>
                  <Popconfirm
                    title="身分証明書をリクエストしてもよろしいですか?"
                    onConfirm={() =>
                      handleIdRequest(text.customerId, text.displayName)
                    }
                    okText="確認"
                    cancelText="閉じる"
                  >
                    <Button type="primary" icon={<IdcardOutlined />}>
                      リクエスト
                    </Button>
                  </Popconfirm>
                </Col>
              </Row>
            )
          ) : text.registrationStage > 9 ? (
            <Row>
              <Col span={24}>
                <Image
                  width={60}
                  className="border border-gray-300"
                  src={`data:image/jpeg;base64,${text.certBlob}`}
                />
                {text.certBlob1 && text.certBlob1 !== null ? (
                  <Image
                    width={60}
                    className="ml-2 border border-gray-300"
                    src={`data:image/jpeg;base64,${text.certBlob1}`}
                  />
                ) : (
                  ""
                )}
              </Col>
            </Row>
          ) : (
            <Radio.Group>
              <Radio.Button disabled style={{ borderColor: "#bfbfbf" }}>
                <MinusOutlined className="m-1" style={{ color: "#bfbfbf" }} />
              </Radio.Button>
            </Radio.Group>
          )}
        </div>
      ),
    },
    {
      title: "面接完了",
      dataIndex: "completed",
      align: "center",
      render: (text, record) => (
        <div
          style={{
            border: text.registrationStage === 10 ? "dashed 1px #3dbaeb" : "",
          }}
        >
          {text.registrationStage === 10 ? (
            <Row justify="center">
              <Col>
                <CustomBadge status="processing" />
              </Col>
            </Row>
          ) : (
            ""
          )}
          {text.registrationStage === 10 ? (
            text.registrationStage === 10 ? (
              <Popconfirm
                title="面接を完了してもよろしいですか?"
                onConfirm={() => handleCompleteRequest(text.customerId)}
                okText="確認"
                cancelText="閉じる"
              >
                <Tooltip title="面接完了" placement="top">
                  <Button
                    style={{
                      borderColor: "#bfbfbf",
                      background: "#d9d9d9",
                    }}
                    className="m-1"
                  >
                    <CheckOutlined
                      style={{
                        color: "#FFF",
                      }}
                    />
                  </Button>
                </Tooltip>
              </Popconfirm>
            ) : (
              <Radio.Group>
                <Radio.Button disabled style={{ borderColor: "#bfbfbf" }}>
                  <MinusOutlined className="m-1" style={{ color: "#bfbfbf" }} />
                </Radio.Button>
              </Radio.Group>
            )
          ) : text.registrationStage > 10 ? (
            <Tooltip title="面接完了" placement="top">
              <Button
                style={{
                  borderColor: "#73d13d",
                  background: "#95de64",
                }}
              >
                <CheckOutlined
                  style={{
                    color: "#FFF",
                  }}
                />
              </Button>
            </Tooltip>
          ) : (
            <Radio.Group>
              <Radio.Button disabled style={{ borderColor: "#bfbfbf" }}>
                <MinusOutlined className="m-1" style={{ color: "#bfbfbf" }} />
              </Radio.Button>
            </Radio.Group>
          )}
        </div>
      ),
    },
    {
      title: "更新日",
      dataIndex: "updatedAt",
      align: "center",
      width: 130,
      render: (text, record) => (
        <span className="whitespace-pre-wrap">
          {moment(text).format("YYYY年M月D日")}
          <br />
          {moment(text).format("HH:mm")}
        </span>
      ),
      sorter: () => {},
    },
    {
      title: "行動",
      dataIndex: "action",
      align: "center",
      render: (text, record) => (
        <div>
          <Space direction="horizontal">
            <Badge style={{ backgroundColor: "#52c41a" }} count={text.unread}>
              <Tooltip title="LINEチャット" placement="top">
                <Button
                  className="border-secondary hover:border-secondary"
                  icon={<CommentOutlined className="text-secondary" />}
                  onClick={() => showLineModal(text.id, text.name)}
                />
              </Tooltip>
            </Badge>
            <Popconfirm
              title="削除してもよろしいですか?"
              onConfirm={() => handleDelete(text.id)}
              okText="削除"
              cancelText="閉じる"
              okType="danger"
              icon={<QuestionCircleOutlined style={{ color: "red" }} />}
            >
              <Tooltip title="削除" placement="top">
                <Button icon={<DeleteOutlined />} danger />
              </Tooltip>
            </Popconfirm>
          </Space>
        </div>
      ),
    },
  ]

  const showLoadingSendSpin = () => {
    setLoadingSendSpin(true)
  }

  const hideLoadingSendSpin = () => {
    setLoadingSendSpin(false)
  }

  const showLoadingSwitchSpin = () => {
    setLoadingSwitchSpin(true)
  }

  const hideLoadingSwitchSpin = () => {
    setLoadingSwitchSpin(false)
  }

  const showLineModal = useCallback(
    (id, name) => {
      showLoadingPageSpin()

      const params = {
        params: {
          id: id,
        },
      }

      Commons.axiosInstance
        .get(Commons.apiChats + "/" + id, params)
        .then(response => {
          if (isMountedRef.current && response && response.data) {
            setChats(response.data)
            setCurrentUser({ id: id, name: name })

            setRegistrations(
              registrations.map(r => {
                if (r.customerId === id) {
                  r.unreadCount = 0
                }

                return r
              }),
            )

            setLineModalVisible(true)
            if (messageSend.current) {
              messageSend.current.focus()
            }
          }
        })
        .catch(error => {
          if (error.response.status === 403) {
            message.warning(Commons.errorSessionMsg)
            history.push(Commons.loginURL)
          } else if (error.response.status === 500) {
            message.error(Commons.errorSystemMsg)
          }
        })
        .finally(() => {
          if (isMountedRef.current) {
            hideLoadingPageSpin()
          }
        })
    },
    [
      hideLoadingPageSpin,
      history,
      isMountedRef,
      registrations,
      showLoadingPageSpin,
    ],
  )

  const hideLineModal = () => {
    lineForm.resetFields()
    setChats([])
    setCurrentUser(false)
    setLineModalVisible(false)
  }

  const showFaqModal = () => {
    setFaqModalVisible(true)
  }

  const hideFaqModal = () => {
    setFaqModalVisible(false)
  }

  const showNoteModal = (text, id) => {
    noteForm.resetFields()
    noteForm.setFieldsValue({
      noteFormItem: text || "",
      noteIdFormItem: id || "",
    })
    setIsNoteModalVisible(true)
  }

  const hideNoteModal = () => {
    setIsNoteModalVisible(false)
  }

  const noteFormOnFinish = data => {
    if (isMountedRef.current) {
      showLoadingSendSpin()

      const paramData = {
        remarks: data.noteFormItem,
      }

      Commons.axiosInstance
        .put(
          Commons.apiRegistrations + "/remarks/" + data.noteIdFormItem,
          paramData,
        )
        .then(response => {
          if (response.status === 200) {
            message.success(Commons.successSaveMsg)
          }
        })
        .catch(error => {
          if (error.response.status === 403) {
            message.warning(Commons.errorSessionMsg)
            history.push(Commons.loginURL)
          } else if (error.response.status === 500) {
            message.error(Commons.errorSystemMsg)
          }
        })
        .finally(() => {
          if (isMountedRef.current) {
            hideLoadingSendSpin()
            hideNoteModal()
          }
        })
    }
  }

  const lineFormOnFinish = data => {
    if (isMountedRef.current) {
      showLoadingSendSpin()

      const postData = {
        customerId: currentUser.id,
        contents: data.lineMessageInput,
      }

      Commons.axiosInstance
        .post(Commons.apiChats, postData)
        .then(response => {
          if (response.status === 200) {
            message.success(Commons.successSentMsg)
            lineForm.resetFields()
            // showLineModal(currentUser.id, currentUser.name)
          }
        })
        .catch(error => {
          if (error.response.status === 403) {
            message.warning(Commons.errorSessionMsg)
            history.push(Commons.loginURL)
          } else if (error.response.status === 500) {
            message.error(Commons.errorSystemMsg)
          }
        })
        .finally(() => {
          if (isMountedRef.current) {
            hideLoadingSendSpin()
          }
        })
    }
  }

  const fetchRegistrationsData = useCallback(
    ({
      perPage = pagination.perPage,
      page = pagination.page,
      sort = pagination.sort,
      sortKey = pagination.sortKey,
    } = {}) => {
      showLoadingPageSpin()

      const params = {
        params: {
          name: form.getFieldValue("regNameFilter") || "",
          ageMin: form.getFieldValue("regAgeMinFilter") || "",
          ageMax: form.getFieldValue("regAgeMaxFilter") || "",
          body: form.getFieldValue("regBtypeFilter") || "",
          prefecture: form.getFieldValue("regPrefectureFilter") || "",
          source: form.getFieldValue("regSourceFilter") || "",
          address: form.getFieldValue("regAddressFilter") || "",
          remarks: form.getFieldValue("regRemarkFilter") || "",
          iStage: form.getFieldValue("regStageFilter"),
          rStage: form.getFieldValue("regCompleteFilter")
            ? form.getFieldValue("regCompleteFilter")
            : undefined,
          isOnHold: form.getFieldValue("regOnHoldFilter")
            ? form.getFieldValue("regOnHoldFilter")
            : undefined,
          pp: perPage,
          p: page,
          sort: sort,
          sortKey: sortKey,
        },
      }

      Commons.axiosInstance
        .get(Commons.apiRegistrations, params)
        .then(response => {
          if (isMountedRef.current && response && response.data) {
            if (
              response.data.registrations &&
              Object.keys(response.data.registrations.rows).length !== 0
            ) {
              setRegistrations(response.data.registrations.rows)
            } else {
              setRegistrations([])
            }

            setPagination({
              perPage: response.data.pp || 20,
              page: response.data.p || 1,
              total: response.data.registrations.count || 0,
              sort: response.data.sort || "desc",
              sortKey: response.data.sortKey || "customerId",
            })
          }
        })
        .catch(error => {
          if (error.response.status === 403) {
            message.warning(Commons.errorSessionMsg)
            history.push(Commons.loginURL)
          } else if (error.response.status === 500) {
            message.error(Commons.errorSystemMsg)
          }
        })
        .finally(() => {
          if (isMountedRef.current) {
            hideLoadingPageSpin()
          }
        })
    },
    [
      isMountedRef,
      form,
      pagination,
      history,
      showLoadingPageSpin,
      hideLoadingPageSpin,
    ],
  )

  const fetchFaqData = useCallback(() => {
    const params = {
      params: {
        type: Commons.faqTemplate,
      },
    }
    Commons.axiosInstance
      .get(Commons.apiTemplates, params)
      .then(response => {
        if (isMountedRef.current && response) {
          if (response.data && response.data.length > 0) {
            setFaqTemplate(response.data)
          } else {
            setFaqTemplate([])
          }
        }
      })
      .catch(error => {
        if (error.response.status === 403) {
          message.warning(Commons.errorSessionMsg)
          history.push(Commons.loginURL)
        } else if (error.response.status === 500) {
          message.error(Commons.errorSystemMsg)
        }
      })
  }, [isMountedRef, history])

  const handleDelete = id => {
    showLoadingPageSpin()

    Commons.axiosInstance
      .delete(Commons.apiRegistrations + "/" + id)
      .then(response => {
        if (response.status === 200) {
          message.success(Commons.successDeleteMsg)
          handleFilter()
        }
      })
      .catch(error => {
        if (error.response.status === 403) {
          message.warning(Commons.errorSessionMsg)
          history.push(Commons.loginURL)
        } else if (error.response.status === 500) {
          message.error(Commons.errorSystemMsg)
        }
      })
      .finally(() => {
        if (isMountedRef.current) {
          hideLoadingPageSpin()
        }
      })
  }

  const handleCallRequest = (id, name) => {
    showLoadingSendSpin()

    const paramData = {
      customerId: id,
    }

    Commons.axiosInstance
      .post(Commons.apiCalls, paramData)
      .then(response => {
        if (response.status === 200) {
          notification.open({
            message: Commons.CALL_TITLE,
            description: name
              ? `「${name}さん」への${Commons.CALL_TITLE}が成功しました`
              : `${Commons.CALL_TITLE}が成功しました`,
            icon: <PhoneOutlined style={{ color: "#52c41a" }} />,
            placement: "bottomRight",
            duration: 10,
          })
        }
      })
      .catch(error => {
        if (error.response.status === 403) {
          message.warning(Commons.errorSessionMsg)
          history.push(Commons.loginURL)
        } else if (error.response.status === 500) {
          notification.open({
            message: Commons.errorSystemMsg,
            description: "",
            icon: <PhoneOutlined style={{ color: "#52c41a" }} />,
            placement: "bottomRight",
          })
        }
      })
      .finally(() => {
        if (isMountedRef.current) {
          hideLoadingSendSpin()
        }
      })
  }

  const handleTableChange = (paginationData, filters, sorter) => {
    fetchRegistrationsData({
      perPage: paginationData.pageSize,
      page: paginationData.current,
      sort:
        sorter && sorter.order
          ? sorter.order === "ascend"
            ? "asc"
            : "desc"
          : undefined,
      sortKey: sorter && sorter.field ? sorter.field : undefined,
    })
  }

  const handleFilter = () => {
    fetchRegistrationsData({ page: 1 })
  }

  const handleFaqTemplate = text => {
    lineForm.setFieldsValue({
      lineMessageInput: text || "",
    })

    hideFaqModal()
  }

  useEffect(fetchRegistrationsData, [])
  useEffect(fetchFaqData, [])
  useEffect(() => {
    socket.on("chatUpdate", response => {
      if (response !== undefined && Object.keys(response).length !== 0) {
        response.forEach(r => {
          if (lineModalVisible && r.customerId === currentUser.id) {
            setChats(chats => [...chats, r])
          } else {
            setRegistrations(
              registrations.map(registration => {
                if (r.customerId === registration.customerId) {
                  registration.unreadCount = r.unreadCount
                  return registration
                } else {
                  return registration
                }
              }),
            )

            notification.open({
              key: r.customerId,
              message: `${r.displayName || ""}`,
              description:
                (r.contents === Commons.CALL_IDENTIFIER
                  ? Commons.CALL_MSG
                  : r.contents === Commons.NOTIFY_IDENTIFIER
                  ? Commons.NOTIFY_MSG
                  : r.contents) || "新しいチャットが来ました",
              icon: <CommentOutlined style={{ color: "#52c41a" }} />,
              placement: "bottomRight",
              style: { cursor: "pointer" },
              duration: 10,
              onClick: () => {
                notification.close(r.customerId)
                if (r.customerId && r.displayName)
                  showLineModal(r.customerId, r.displayName)
              },
            })
          }
        })
      }
    })

    socket.on("registrationUpdate", response => {
      if (response !== undefined && Object.keys(response).length !== 0) {
        if (
          response[0].customerId === undefined &&
          response[0].dataValues.customerId
        ) {
          Commons.axiosInstance
            .get(
              Commons.apiRegistrations +
                "/" +
                response[0].dataValues.customerId,
            )
            .then(response => {
              if (isMountedRef.current && response) {
                if (response.data.customerId) {
                  setRegistrations(
                    registrations.map(registration => {
                      if (
                        response.data.customerId === registration.customerId
                      ) {
                        return response.data
                      } else {
                        return registration
                      }
                    }),
                  )
                }
              }
            })
        } else {
          response.forEach(r => {
            let updated = false

            setRegistrations(
              registrations.map(registration => {
                if (r.customerId === registration.customerId) {
                  updated = true

                  return r
                } else {
                  return registration
                }
              }),
            )

            if (!updated && pagination.page === 1)
              setRegistrations([r, ...registrations])
          })
        }
      }
    })

    return () => {
      socket.off("chatUpdate")
      socket.off("registrationUpdate")
    }
  }, [
    isMountedRef,
    chats,
    fetchRegistrationsData,
    registrations,
    currentUser,
    lineModalVisible,
    showLineModal,
  ])

  return (
    <div>
      <Card title="面接管理" bordered={false} className="h-full">
        <Card
          title="検索フィルタ"
          bordered={true}
          type="inner"
          className="h-full mb-5"
          headStyle={{
            color: "#FFF",
            backgroundColor: "#3dbaeb",
          }}
          style={{
            borderColor: "#3dbaeb",
          }}
        >
          <Form
            form={form}
            name="registrationsFilter"
            onFinish={handleFilter}
            noValidate
          >
            <Row gutter={[8, 8]}>
              <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 6 }}>
                <Form.Item
                  help="お名前"
                  name="regNameFilter"
                  style={{ marginBottom: 5 }}
                >
                  <Input placeholder="例：みなと" allowClear />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 6 }}>
                <Form.Item
                  help="体型"
                  name="regBtypeFilter"
                  style={{ marginBottom: 5 }}
                >
                  <Select placeholder="体型を選択してください" allowClear>
                    {BODY_TYPE.map(type => (
                      <Option key={type} value={type}>
                        {type}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 6 }}>
                <Form.Item
                  help="最低年齢"
                  name="regAgeMinFilter"
                  style={{ marginBottom: 5 }}
                >
                  <InputNumber
                    min={0}
                    max={100}
                    className="w-full"
                    formatter={value => `${value}歳`}
                    onPressEnter={e => e.preventDefault()}
                  />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 6 }}>
                <Form.Item
                  help="最大年齢"
                  name="regAgeMaxFilter"
                  style={{ marginBottom: 5 }}
                >
                  <InputNumber
                    min={0}
                    max={100}
                    className="w-full"
                    formatter={value => `${value}歳`}
                    onPressEnter={e => e.preventDefault()}
                  />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 6 }}>
                <Form.Item
                  help="都道府県"
                  name="regPrefectureFilter"
                  style={{ marginBottom: 5 }}
                >
                  <Input placeholder="例：愛知県" allowClear />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 6 }}>
                <Form.Item
                  help="住所"
                  name="regAddressFilter"
                  style={{ marginBottom: 5 }}
                >
                  <Input placeholder="例：名古屋市中区栄" allowClear />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 6 }}>
                <Form.Item
                  help="情報源"
                  name="regSourceFilter"
                  style={{ marginBottom: 5 }}
                >
                  <Input placeholder="例：Twitter" allowClear />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 6 }}>
                <Form.Item
                  help="面接状態"
                  name="regStageFilter"
                  style={{ marginBottom: 5 }}
                >
                  <Select placeholder="面接状態を選択してください" allowClear>
                    {STATUS.map((s, i) => (
                      <Option key={i} value={i}>
                        {s}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }}>
                <Form.Item
                  help="備考"
                  name="regRemarkFilter"
                  style={{ marginBottom: 5 }}
                >
                  <Input placeholder="例：12月22日20時面接" allowClear />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[8, 8]} justify="start">
              <Col>
                <Form.Item
                  name="regCompleteFilter"
                  style={{ marginBottom: 5 }}
                  valuePropName="checked"
                >
                  <Checkbox>面接完了</Checkbox>
                </Form.Item>
              </Col>
              <Col>
                <Form.Item
                  name="regOnHoldFilter"
                  style={{ marginBottom: 5 }}
                  valuePropName="checked"
                >
                  <Checkbox>面接保留</Checkbox>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[8, 8]} justify="end">
              <Col>
                <Form.Item style={{ marginBottom: 5 }}>
                  <Button type="primary" htmlType="submit">
                    検索
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Card>
        <Card
          title="申込者リスト"
          bordered={true}
          type="inner"
          className="h-full"
          headStyle={{
            color: "#FFF",
            backgroundColor: "#3dbaeb",
          }}
          style={{
            borderColor: "#3dbaeb",
          }}
          bodyStyle={{
            padding: 0,
          }}
        >
          <Table
            bordered
            size="small"
            onChange={handleTableChange}
            columns={columns}
            scroll={{ x: true }}
            pagination={{
              responsive: true,
              current: pagination.page,
              pageSize: pagination.perPage,
              total: pagination.total,
              showTotal: (total, range) =>
                `全${total}件中${range[0]}～${range[1]}件目`,
              defaultCurrent: 1,
              defaultPageSize: 20,
              position: ["bottomCenter"],
            }}
            expandable={{
              expandedRowRender: r => (
                <Row>
                  <Col span={24}>
                    <Tag
                      color="blue"
                      style={{ fontSize: "14px" }}
                      className="m-1 p-2"
                    >
                      都道府県： {r.prefecture}
                    </Tag>
                    <Tag
                      color="blue"
                      style={{ fontSize: "14px" }}
                      className="m-1 p-2"
                    >
                      住所： {r.address}
                    </Tag>
                    <Tag
                      color="blue"
                      style={{ fontSize: "14px" }}
                      className="m-1 p-2"
                    >
                      体型： {r.btype}
                    </Tag>
                    <Tag
                      color="blue"
                      style={{ fontSize: "14px" }}
                      className="m-1 p-2"
                    >
                      情報源： {r.source}
                    </Tag>
                    <Tag
                      color="blue"
                      style={{ fontSize: "14px" }}
                      className="m-1 p-2"
                    >
                      友達：{" "}
                      {r.isFollowing ? (
                        <CheckSquareTwoTone twoToneColor="#52c41a" />
                      ) : (
                        <CloseSquareTwoTone twoToneColor="#ff4d4f" />
                      )}
                    </Tag>
                  </Col>
                </Row>
              ),
            }}
            dataSource={
              registrations
                ? registrations.map(r => {
                    return {
                      key: r.customerId,
                      customerId: r.customerId,
                      picUrl: r.picUrl,
                      displayName: r.displayName,
                      source: r.source,
                      age: r.age,
                      isOnHold: r,
                      btype: r.btype,
                      isFollowing: r.isFollowing,
                      status: r,
                      appointment: r,
                      certificate: r,
                      prefecture: r.prefecture,
                      address: r.address,
                      remarks: r,
                      updatedAt: r.updatedAt,
                      completed: r,
                      action: {
                        id: r.customerId,
                        name: r.displayName,
                        unread: r.unreadCount,
                      },
                    }
                  })
                : []
            }
          />
        </Card>
      </Card>
      <Modal
        title="備考追加"
        visible={isNoteModalVisible}
        onCancel={hideNoteModal}
        footer={null}
        centered
      >
        <Row>
          <Col span={24}>
            <Form form={noteForm} name="noteForm" onFinish={noteFormOnFinish}>
              <Row gutter={[0, 16]}>
                <Col span={24}>
                  <Form.Item name="noteFormItem">
                    <Input.TextArea
                      allowClear
                      bordered
                      showCount
                      autoSize={{ minRows: 5, maxRows: 15 }}
                      maxLength={5000}
                      placeholder="備考を入力してください"
                    />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item name="noteIdFormItem" hidden>
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    className="text-center"
                    style={{ marginBottom: 0 }}
                  >
                    <Button
                      type="primary"
                      htmlType="submit"
                      loading={loadingSendSpin}
                    >
                      保存
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </Modal>
      <Modal
        title={
          currentUser && currentUser.name ? currentUser.name : "LINEチャット"
        }
        visible={lineModalVisible}
        onCancel={hideLineModal}
        footer={null}
        bodyStyle={{
          height: "90vh",
          overflow: "auto",
          padding: 0,
          backgroundColor: "#e6f7ff",
        }}
        centered
      >
        <div
          className="pb-4"
          style={{ backgroundColor: "#fafafa", minHeight: "60vh" }}
        >
          {chats && chats.length > 0
            ? chats.map(chat => (
                <Row key={chat.chatId}>
                  <Col span={22} offset={chat.source === "user" ? 0 : 2}>
                    <div className="m-1">
                      {chat.source === "user" ? (
                        <div className="flex flex-row items-start justify-start">
                          <div className="mr-1">
                            <Avatar
                              className="bg-secondary"
                              icon={<UserOutlined />}
                            />
                          </div>
                          <div className="text-white bg-secondary rounded-lg p-3 cursor-pointer mr-1">
                            <span className="whitespace-pre-wrap">
                              {chat.contents || ""}
                            </span>
                          </div>
                          <div>
                            <Tag
                              className="whitespace-pre-wrap"
                              style={{ fontSize: "10px", color: "#8c8c8c" }}
                            >
                              {chat.createdAt
                                ? moment(chat.createdAt).format(
                                    "YYYY/MM/DD HH:mm",
                                  )
                                : ""}
                            </Tag>
                          </div>
                        </div>
                      ) : (
                        <div className="flex flex-row items-start justify-end">
                          <div>
                            <Tag
                              className="whitespace-pre-wrap"
                              style={{ fontSize: "10px", color: "#8c8c8c" }}
                            >
                              {chat.createdAt
                                ? moment(chat.createdAt).format(
                                    "YYYY/MM/DD HH:mm",
                                  )
                                : ""}
                            </Tag>
                          </div>
                          {chat.contents === "[call]" ? (
                            <div className="text-white bg-primary rounded-lg p-3 cursor-pointer">
                              <Row>
                                <Col span={24} className="text-center">
                                  <PhoneFilled
                                    rotate={90}
                                    className="text-4xl"
                                  />
                                </Col>
                                <Col span={24}>
                                  <span className="text-base font-bold">
                                    {Commons.CALL_TITLE}
                                  </span>
                                </Col>
                                <Col span={24}>
                                  <span className="text-sm">
                                    {Commons.CALL_MSG}
                                  </span>
                                </Col>
                              </Row>
                            </div>
                          ) : chat.contents === "[bell]" ? (
                            <div className="text-white bg-primary rounded-lg p-3 cursor-pointer">
                              <Row>
                                <Col span={24} className="text-center">
                                  <BellFilled className="text-4xl" />
                                </Col>
                                <Col span={24}>
                                  <span className="text-base font-bold">
                                    {Commons.NOTIFY_TITLE}
                                  </span>
                                </Col>
                                <Col span={24}>
                                  <span className="text-sm">
                                    {Commons.NOTIFY_MSG}
                                  </span>
                                </Col>
                              </Row>
                            </div>
                          ) : (
                            <div className="text-white bg-primary rounded-lg p-3 cursor-pointer">
                              <span className="whitespace-pre-wrap">
                                {chat.contents || ""}
                              </span>
                            </div>
                          )}
                          <div className="ml-1">
                            {chat.channel === "chat" ? (
                              <Avatar src="avatar_chat.jpg" />
                            ) : chat.channel === "bot" ? (
                              <Avatar src="avatar_bot.jpg" />
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                  </Col>
                </Row>
              ))
            : ""}
        </div>
        <div
          className="p-4 border-t-2 border-solid border-primary"
          style={{ backgroundColor: "#e6f7ff" }}
        >
          <Row>
            <Col span={24}>
              <Form
                form={lineForm}
                name="lineMessageForm"
                onFinish={lineFormOnFinish}
                colon={false}
                requiredMark={false}
              >
                <Row align="top" gutter={[8, 8]}>
                  {currentUser ? (
                    <Col span={24}>
                      <div className="block text-right">
                        <Tooltip title="返事リスト" placement="left">
                          <Button
                            type="primary"
                            style={{ borderColor: "#FFF" }}
                            className="m-1"
                            icon={<MessageOutlined style={{ color: "#FFF" }} />}
                            onClick={showFaqModal}
                          />
                        </Tooltip>
                        <Popconfirm
                          title={`申込者に${Commons.CALL_TITLE}を送信してもよろしいですか？`}
                          onConfirm={() =>
                            handleCallRequest(currentUser.id, currentUser.name)
                          }
                          okText="確認"
                          cancelText="閉じる"
                          icon={<QuestionCircleOutlined />}
                        >
                          <Tooltip
                            title={Commons.CALL_TITLE}
                            placement="bottom"
                          >
                            <Button
                              type="primary"
                              style={{ borderColor: "#FFF" }}
                              className="m-1"
                              icon={<PhoneOutlined style={{ color: "#FFF" }} />}
                              loading={loadingSendSpin}
                            />
                          </Tooltip>
                        </Popconfirm>
                        <Tooltip title="送る" placement="top">
                          <Button
                            type="primary"
                            size="large"
                            className="ml-1 my-1"
                            style={{ borderColor: "#FFF" }}
                            icon={<SendOutlined style={{ color: "#FFF" }} />}
                            htmlType="submit"
                            loading={loadingSendSpin}
                          />
                        </Tooltip>
                      </div>
                    </Col>
                  ) : (
                    ""
                  )}
                  <Col span={24}>
                    <Form.Item
                      className="block"
                      name="lineMessageInput"
                      rules={[
                        {
                          required: true,
                          message: "メッセージを入力してください",
                        },
                      ]}
                    >
                      <CustomTextArea
                        allowClear
                        bordered
                        showCount
                        autoSize={{ minRows: 5, maxRows: 10 }}
                        maxLength={5000}
                        placeholder="メッセージを入力してください"
                        ref={messageSend}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
        </div>
      </Modal>
      <Modal
        title="返事のリスト"
        visible={faqModalVisible}
        onCancel={hideFaqModal}
        bodyStyle={
          faqTemplate && faqTemplate.length > 0
            ? { backgroundColor: "#e6f7ff" }
            : {}
        }
        footer={null}
        centered
      >
        <List
          itemLayout="horizontal"
          dataSource={faqTemplate}
          renderItem={template => (
            <List.Item>
              <div
                className="p-4 cursor-pointer border-dashed border-2 border-primary rounded-lg bg-white text-black text-opacity-75 hover:text-white hover:bg-primary hover:border-white transition duration-200"
                onClick={() => handleFaqTemplate(template.contentText)}
              >
                <span className="whitespace-pre-wrap">
                  {template.contentText || ""}
                </span>
              </div>
            </List.Item>
          )}
        />
      </Modal>
    </div>
  )
}

export default withRouter(Home)
